import Button from "@components/Button";
import { Input } from "@nextui-org/input";
import { ErrorMessage, Form, FormikProps } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import "react-phone-number-input/style.css";
import { EmailFormType } from "@queries/sendEmail";
import TextError from "@components/TextError";
import { getItem } from "@utils/localStorage";

const EmailForm: React.FC<
  FormikProps<EmailFormType> & {
    timeout?: number;
    sessionExpired?: boolean;
  }
> = ({
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  timeout,
  touched,
  values,
  errors,
  isValid,
  sessionExpired = false,
}) => {
  const inputRef = useRef(null);

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      (inputRef.current as HTMLInputElement).focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (getItem("email") && !sessionExpired && isValid) {
      handleSubmit();
    }
  }, [handleSubmit, isValid, sessionExpired]);

  return (
    <Form className="text-center">
      <motion.div
        key="form-field"
        className="mt-3 text-left bg-white rounded-xl border border-gray-100 py-4 px-3"
      >
        <label
          className="inline-block text-left text-[#384250] text-sm font-medium mb-2"
          htmlFor="email"
        >
          Enter email address
        </label>

        <Input
          variant="bordered"
          onValueChange={(value) => {
            setFieldValue("email", value);
          }}
          id="email"
          size="lg"
          fullWidth
          radius="sm"
          isRequired
          onBlur={handleBlur}
          placeholder="Email"
          autoFocus
          classNames={{
            inputWrapper: "bg-white shadow-card-grey px-5 py-[0.88rem]",
            input: "font-normal text-sm placeholder-shown:text-[#6C737F]",
          }}
          baseRef={inputRef}
          name="email"
          value={values["email"]}
          isInvalid={
            touched["email"] && Boolean(typeof errors["email"] !== "undefined")
          }
          onKeyDown={handleKeyDown}
        />
      </motion.div>

      <ErrorMessage name="email" className="text-left" component={TextError} />

      <Button
        color="primary"
        className="mt-5 py-[0.62rem]"
        data-dd-action-name="login.submit-button"
        fullWidth
        isDisabled={!!timeout}
        isLoading={isSubmitting || Boolean(timeout)}
        type="submit"
      >
        {timeout ? `Try in ${timeout}` : "Continue"}
      </Button>
    </Form>
  );
};

export default EmailForm;
