import { fromUnixTimestamp } from "./fromUnixTimestamp";

const setRedirectionTokenExpiration = ({
  expires_at,
  path,
  searchParamName = "sessionExpired",
  search = "?",
}: {
  expires_at: UnixTime;
  path: string;
  searchParamName?: string;
  /* Todo: take type of search from Tanstack router and check if there is a way to craft path string  */
  search?: string;
}): void => {
  let meta = document.querySelector(
    `[http-equiv='refresh'][content*='${path}']`,
  );
  const time = new Date(fromUnixTimestamp(expires_at)).getTime();
  const diff = Math.abs(Date.now() - time) / 1000;

  if (typeof window === "undefined") return;

  if (!meta) {
    meta = document.createElement("meta");
    meta.setAttribute("http-equiv", "refresh");
    meta.setAttribute(
      "content",
      `${diff};url=${window.location.origin}${path}${search}${
        search === "?" ? "" : "&"
      }${searchParamName}=true`,
    );

    document.head.appendChild(meta);
  } else {
    meta.setAttribute(
      "content",
      `${diff};url=${window.location.origin}${path}${search}${
        search === "?" ? "" : "&"
      }${searchParamName}=true`,
    );
  }
};

export const deleteRedirectionTokenExpiration = () => {
  if (typeof window === "undefined") return;

  const metaTags = document.head.getElementsByTagName("meta");

  if (metaTags.length > 0) {
    Array.from(metaTags).forEach((tag) => {
      if (tag.httpEquiv && tag.httpEquiv === "refresh") {
        tag.remove();
      }
    });
  }
};

export default setRedirectionTokenExpiration;
