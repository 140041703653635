"use client";
import EmailFormik from "@components/email/EmailFormik";
import { useAuth } from "@hooks/useAuth";
import { getItem } from "@utils/localStorage";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect } from "react";

const LoginPage: React.FC = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { isAuthenticated } = useAuth();

  const handleSuccess = (_: string, userExist: boolean) => {
    if (userExist) {
      router.push(`/verification?${new URLSearchParams(searchParams)}`);
    } else {
      router.push(`/enter-number?${new URLSearchParams(searchParams)}`);
    }
  };

  useEffect(() => {
    router.prefetch("/verification");
    router.prefetch("/enter-number");
  }, [router]);

  useEffect(() => {
    if (isAuthenticated) {
      router.push("/pay");
    }
  }, [isAuthenticated, router]);

  return (
    <EmailFormik
      callback={handleSuccess}
      sessionExpired={searchParams.get("sessionExpired") === "true"}
      email={getItem("email") ?? ""}
    />
  );
};

export default LoginPage;
