import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { twMerge } from "tailwind-merge";

const TextError: React.FC<
  React.PropsWithChildren<{
    className?: string;
  }>
> = ({ children, className, ...rest }) => {
  return (
    <AnimatePresence>
      <motion.p
        {...rest}
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 50 }}
        className={twMerge("text-danger mt-2 text-sm font-normal", className)}
      >
        {children}
      </motion.p>
    </AnimatePresence>
  );
};

export default TextError;
