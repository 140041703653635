import { Formik } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { EmailFormType, EmailSchema } from "@queries/sendEmail";
import EmailForm from "./EmailForm";
import useEmailForm from "./useEmailForm";

const initialValues = EmailSchema.getDefault();

const EmailFormik: React.FC<{
  callback: (email: string, userExist: boolean) => void;
  sessionExpired?: boolean;
  email: string;
}> = ({ callback, email, sessionExpired = false }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { time, onSubmit } = useEmailForm({
    callback,
    executeRecaptcha,
  });

  if (!executeRecaptcha) {
    return <>Loading...</>;
  }

  return (
    <Formik<EmailFormType>
      initialValues={Object.assign({}, initialValues, { email })}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      validationSchema={EmailSchema}
    >
      {(props) => (
        <EmailForm
          timeout={time}
          sessionExpired={sessionExpired}
          {...props}
          isValid={Boolean(executeRecaptcha) && props.isValid}
        />
      )}
    </Formik>
  );
};

export default EmailFormik;
