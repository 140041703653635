import { useEffect, useState } from "react";

export interface UseTimerOutput {
  time: number;
  reset: () => void;
  stop: () => void;
  play: () => void;
  restart: (newTime: number) => void;
}

const useTimer = (start: number): UseTimerOutput => {
  const [time, setTime] = useState(start);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isPlaying) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isPlaying]);

  const play = () => setIsPlaying(true);
  const stop = () => setIsPlaying(false);
  const reset = () => setTime(0);
  const restart = (newValue: number) => {
    setTime(newValue);
    play();
  };

  useEffect(() => {
    if (time < 1) stop();
  }, [time]);

  return { time, play, stop, reset, restart };
};

export default useTimer;
