import { ButtonProps as BaseButtonProps, useButton } from "@nextui-org/button";
import { cn } from "@nextui-org/react";
import { Ripple } from "@nextui-org/ripple";
import { Spinner } from "@nextui-org/spinner";
import { forwardRef } from "react";

export interface ButtonProps extends BaseButtonProps {}

/* Primary */

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    Component,
    domRef,
    children,
    spinnerSize,
    styles,
    spinner = <Spinner color="current" size={spinnerSize} />,
    spinnerPlacement,
    startContent,
    endContent,
    isLoading,
    disableRipple,
    getButtonProps,
    getRippleProps,
    isIconOnly,
  } = useButton({
    ref,
    ...props,
    radius: props.radius ?? "sm",
    size: props.size ?? "lg",
    className: cn(
      "font-medium text-base",
      {
        "border-black/[.08] text-gray-25 shadow-md bg-secondary data-[hover=true]:opacity-100 data-[hover=true]:border-black/[.14] data-[hover=true]:bg-primary-button-hover data-[pressed=true]:shadow-xs data-[pressed=true]:bg-primary-button-pressed data-[disabled=true]:bg-[#4D5761] data-[disabled=true]:text-gray-100 data-[loading=true]:bg-secondary data-[loading=true]:opacity-100 data-[loading=true]:text-gray-200 ":
          props.color === "secondary",
        "h-[3.25rem]": props.size === "lg" || typeof props.size === "undefined",
      },
      props.className,
    ),
  });

  return (
    <Component ref={domRef} className={styles} {...getButtonProps()}>
      {startContent}
      {isLoading && spinnerPlacement === "start" && spinner}
      {isLoading && isIconOnly ? null : children}
      {isLoading && spinnerPlacement === "end" && spinner}
      {endContent}
      {!disableRipple && <Ripple {...getRippleProps()} />}
    </Component>
  );
});

Button.displayName = "MyButton";

export default Button;
