import { datadogRum } from "@datadog/browser-rum";
import useTimer, { UseTimerOutput } from "@hooks/useTimer";
import { jam } from "@jam.dev/sdk";
import {
  UserExistData,
  useCheckUserExistMutation,
} from "@queries/checkUserExist";
import {
  EmailFormType,
  SendEmailResponse,
  useSendEmailMutation,
} from "@queries/sendEmail";
import { useProductStore } from "@stores/product";
import { getItem, setItem } from "@utils/localStorage";
import setRedirectionTokenExpiration from "@utils/setRedirectionTokenExpiration";
import { AxiosResponse } from "axios";
import { FormikConfig } from "formik";
import { toast } from "sonner";

interface UseEmailFormInput {
  callback: (email: string, userExist: boolean) => void;
  executeRecaptcha:
    | ((action?: string | undefined) => Promise<string>)
    | undefined;
}

interface UseEmailFormOutput extends Pick<UseTimerOutput, "time" | "restart"> {
  handleSubmit: (
    values: EmailFormType,
  ) => void | Promise<
    | void
    | [
        AxiosResponse<SendEmailResponse, unknown>,
        AxiosResponse<UserExistData, unknown>,
      ]
  >;
  onSubmit: FormikConfig<EmailFormType>["onSubmit"];
}

const useEmailForm = ({
  executeRecaptcha,
  callback,
}: UseEmailFormInput): UseEmailFormOutput => {
  const getSession = useProductStore((state) => state.getSession);
  const { time, restart } = useTimer(0);
  const { mutateAsync } = useCheckUserExistMutation();
  const submit = useSendEmailMutation({
    onError: (error) => {
      if (error?.response?.data?.error?.num_code === 63003) {
        return;
      }
    },
  });

  const handleSubmit = async (values: EmailFormType) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      throw new Error("Execute recaptcha not yet available");
    }

    values.email = values.email.toLocaleLowerCase();

    const token = await executeRecaptcha("submit");
    const checkToken = await executeRecaptcha("checkUser");
    const sentBy = "email";
    const sessionItems = JSON.stringify(getSession("payment")?.items ?? []);
    const data = {
      ...values,
      phone_number: values.phone_number ?? getItem("phone_number")!,
      "g-recaptcha-response": token,
      items: sessionItems,
    };

    datadogRum.addAction("login.submit");
    datadogRum.addTiming("login.submit");

    try {
      const checkUserRes = await mutateAsync({
        type: sentBy,
        value: values.email,
        "g-recaptcha-response": checkToken,
        items: sessionItems,
      });

      const submitRes = await submit.mutateAsync(data);

      datadogRum.setUser({
        email: values.email,
      });

      jam.metadata(() => {
        return {
          timeSincePageLoad: performance.now(),
          email: values.email,
        };
      });

      if (submitRes.status === 200) {
        datadogRum.addAction("login.submit.successful");
        setItem("email", values.email);
        values.phone_number && setItem("phone_number", values.phone_number);

        setRedirectionTokenExpiration({
          expires_at: submitRes.data.data.expires_at,
          path: "/login",
          search: typeof window !== "undefined" ? window?.location.search : "",
        });

        callback(
          values.email,
          checkUserRes.data.data.user_in_system ||
            Boolean(data.email && data.phone_number),
        );
      }

      return [submitRes, checkUserRes] as [
        AxiosResponse<SendEmailResponse, unknown>,
        AxiosResponse<UserExistData, unknown>,
      ];
    } catch (error: any) {
      datadogRum.addAction("login.submit.failed", {
        status: error.response.status ?? "500",
        message: error.message,
      });

      if (
        error.response.status === 400 &&
        error.response.data.error.code === "INVALID_ID"
      ) {
        setItem("email", values.email);

        callback(values.email, false);

        return;
      }

      if (error.response?.status === 429) {
        const retryAt = Number(error.response.headers["retry-at"] ?? 30);

        toast.info(
          `Too many requests. Please wait and try again in ${retryAt} seconds.`,
        );

        restart(retryAt);
      }

      throw error;
    }
  };

  const onSubmit = async (values: EmailFormType) => {
    return handleSubmit(values).catch((error) => {
      const messages = error.response.data.error.messages
        ? Object.values(error.response.data.error.messages)
        : [];

      if (messages.length > 0) {
        const result = messages.join(". ");
        toast.error(result);

        return;
      }

      if (
        error.response.status === 403 &&
        error.response?.data?.error?.code === "CONTENT_BLOCK_BY_COUNTRY"
      ) {
        toast.error(
          "This content is not available in the country or region you’re currently in.",
        );

        return;
      }

      if (error.response?.data.error.code) {
        toast.error(error.response?.data.error.code);
      }
    });
  };

  return {
    time,
    restart,
    onSubmit,
    handleSubmit,
  };
};

export default useEmailForm;
